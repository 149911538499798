<template>
    <page-layout>
        <v-container class="shop fill-height py-6 px-4 px-1 px-md-4">
            <router-view/>
        </v-container>
    </page-layout>
</template>

<script>
    import CheckoutHelper from "../../mixins/CheckoutHelper";

    export default {
        name: "Checkout",
        data() {
            return {
                loading: true,
            }
        },

        mixins: [
            CheckoutHelper
        ],
        mounted() {
            this.loading = false;

        },
        methods: {},
        computed: {
            customer: {
                set: function (newValue) {
                    this.$store.commit('session/customer', newValue);
                },
                get: function () {
                    return this.$store.getters['session/customer'];

                }
            }
        }
    }
</script>

<style scoped>

</style>
